<template>
  <div class="d-flex flex-column flex-root">
    <div id="kt_login" class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white">
      <!--begin::Aside-->
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat bgi-position-x-left p-10 animate__animated animate__bounceInLeft"
        :style="{
          backgroundImage: `linear-gradient(to top, rgba(23, 177, 198, 0.6) 0%, rgba(151, 236, 218, 0.1) 30%), url('/media/bg/login-bg.jpg')`
        }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column">
          <!--begin: Aside header -->

          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center animate__animated animate__lightSpeedInLeft"
            style="max-width: 260px"
          >
            <div class="text-center">
              <a href="#" class="flex-column-auto">
                <img src="/media/logos/logo-with-name-black-vertical.png" width="90%" />
              </a>
            </div>
            <h3 class="font-size-h1 mt-10 mb-5 text-dark">Bem vindo de volta</h3>
            <p class="font-weight-lighter text-dark">
              Criptoativos garantidos pelo seu próprio imóvel para uso como meio de pagamento em diversas transações do
              dia a dia.
            </p>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between">
            <div class="opacity-90 font-weight-bold text-dark">© 2021 SnailBank</div>
            <div class="d-flex font-weight-bold">
              <a href="#" class="text-dark">Termos de Privacidade</a>
              <a href="#" class="text-dark ml-10">Termos legais</a>
              <a href="#" class="text-dark ml-10">Contato</a>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column">
        <div class="d-flex flex-column-fluid flex-center">
          <router-view />
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { mapState } from 'vuex';

let installEvent;
export default {
  name: 'Auth',
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      installEvent = e;
      //confirmation pwa
      // this.$swal({
      //   title: 'Aplicativo compativel com seu celular?',
      //   text: 'Quer adicionar este aplicativo à sua tela inicial?',
      //   icon: 'warning',
      //   buttons: true,
      //   dangerMode: true
      // }).then((willDelete) => {
      //   if (willDelete) {
      //     this.install();
      //   }
      // });
    });
  },

  methods: {
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    }
  }
};
</script>
